// import { frontendURL } from '../../../../helper/URLHelper';
// const SettingsContent = () => import('../Wrapper.vue');
// const Index = () => import('./Index.vue');

export default {
  routes: [
    // {
    //   path: frontendURL('accounts/:accountId/campaigns'),
    //   component: SettingsContent,
    //   props: {
    //     headerTitle: 'CAMPAIGN.ONGOING.HEADER',
    //     icon: 'arrow-swap',
    //   },
    //   children: [
    //     {
    //       path: '',
    //       redirect: 'ongoing',
    //     },
    //     {
    //       path: 'ongoing',
    //       name: 'ongoing_campaigns',
    //       roles: ['administrator'],
    //       component: Index,
    //     },
    //   ],
    // },
    // {
    //   path: frontendURL('accounts/:accountId/campaigns'),
    //   component: SettingsContent,
    //   props: {
    //     headerTitle: 'CAMPAIGN.ONE_OFF.HEADER',
    //     icon: 'sound-source',
    //   },
    //   children: [
    //     {
    //       path: 'one_off',
    //       name: 'one_off',
    //       roles: ['administrator'],
    //       component: Index,
    //     },
    //   ],
    // },
  ],
};
